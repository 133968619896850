const FILTERED_DATA_CHUNKS_ADD_YEAR = "FILTERED_DATA_CHUNKS_ADD_YEAR";

const ACTION_FILTERED_DATA_CHUNKS_ADD_YEAR = (data) => {
  return {
    type: FILTERED_DATA_CHUNKS_ADD_YEAR,
    data,
  };
};

export default ACTION_FILTERED_DATA_CHUNKS_ADD_YEAR;
