const ASSIGN_FILTERED_DATA_CHUNKS = "ASSIGN_FILTERED_DATA_CHUNKS";

const ACTION_ASSIGN_FILTERED_DATA_CHUNKS = (data) => {
  return {
    type: ASSIGN_FILTERED_DATA_CHUNKS,
    data,
  };
};

export default ACTION_ASSIGN_FILTERED_DATA_CHUNKS;
