const SEX_TIMELINE_COLUMNS = "SEX_TIMELINE_COLUMNS";

const ACTION_SEX_TIMELINE_COLUMNS = (columns) => {
  return {
    type: SEX_TIMELINE_COLUMNS,
    columns,
  };
};

export default ACTION_SEX_TIMELINE_COLUMNS;
